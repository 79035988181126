import React from "react";
import "./Footer.css"

const Footer = () => {
  return (
    <div className="footer">
      © 2022 Tay West
    </div>
  )
}

export default Footer